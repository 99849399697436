<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <span class="text-white m-3">{{
        $store.state.auth.user.username.toUpperCase()
      }}</span>
      <a class="btn btn-sm btn-danger" href="/logout">
        <i class="ri-shut-down-line align-middle mr-1 text-white"></i>
        {{ $t("navbar.dropdown.kevin.list.logout") }}
      </a>
      <!-- <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a> -->
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Make Payment</h4>
                        <p class="text-muted">This is the final step!</p>
                      </div>

                      <div class="p-2 mt-1">
                        <b-alert
                          :variant="notification.type"
                          class="mt-1"
                          v-if="notification.message"
                          show
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >{{ notification.message }}</b-alert
                        >
                        <div class="mt-1 text-center">
                          <!-- <h2 class="mt-0">
                            <i class="mdi mdi-check-all"></i>
                          </h2> -->
                          <p class="mb-2 mx-auto">
                            Make a payment of <b>₦25,000</b> to the account below.
                          </p>
                          <h5>CadLands Real Estate Limited<br>
                          UBA: 1022804540
                          </h5>
                          
                          <p class="mb-2 mx-auto">
                            Send proof of payment to activate your account.
                          </p>
                          <a class="btn btn-sm btn-success" :href="'https://wa.me/+2349022000200?text=My Email: ' + $store.state.auth.user.email + '. Username: ' + $store.state.auth.user.username">
                            <i class="ri-whatsapp-fill align-middle mr-1 text-white"></i>
                            Send Proof of Payment
                          </a>
                        </div>
                        <!-- <div class="mt-1 text-center">
                          <paystack
                            :amount="amount"
                            :email="$store.state.auth.user.email"
                            :paystackkey="paystackkey"
                            :reference="reference"
                            :callback="callback"
                            :close="close"
                            :embed="false"
                            class="
                              btn btn-primary
                              w-md
                              waves-effect waves-light
                            "
                          >
                            <i class="fas fa-money-bill-alt"></i>
                            Make Payment
                            <b-spinner
                              v-show="validatingPayment"
                              small
                              class="align-middle"
                            ></b-spinner>
                          </paystack>
                        </div> -->
                      </div>

                      <div class="mt-5 text-center">
                        <!-- <p>
                          Have already paid?
                          <router-link
                            tag="a"
                            to="/verify-payment"
                            class="font-weight-medium text-primary"
                            >Verify Payment</router-link
                          >
                        </p> -->
                        <p>
                          © 2020 Cadlands. Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by Encodeup
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script type="text/javascript">
// import paystack from "vue-paystack";
import { userService } from "@/services/user.service";

export default {
  components: {
    // paystack,
  },
  data() {
    return {
      paystackkey: process.env.VUE_APP_PAYSTACK_PK, //paystack public key
      //   email: "uchennapaschal50@gmail.com", // Customer email
      amount: 2500000, // in kobo
      validatingPayment: false,
    };
  },
  mounted() {
    // fetch payment Details
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    callback: async function (response) {
      console.log(response);
      // record payment with reference
      try {
        this.validatingPayment = true;
        this.$store.dispatch(
          "notification/success",
          "Payment Successful!<br>Please wait while we validate your payment."
        );
        await userService.validatePayment({ reference: response.reference });
        this.$router.push("/");
        this.validatingPayment = false;
      } catch (error) {
        this.validatingPayment = false;
        switch (error.response.status) {
          case 403:
            this.errors = error.response.data.errors;
            break;
          case 422:
            this.errors = error.response.data.errors;
            break;
          case 500:
            this.$store.dispatch(
              "notification/error",
              error.response.data.message
            );
            break;
          default:
            this.$store.dispatch(
              "notification/error",
              "Something went wrong. Please try again!"
            );
            break;
        }
        // this.$store.dispatch("notification/error", error);
      }
    },
    close: function () {
      console.log("Payment closed");
    },
  },
};
</script>